.mapMode{
    background-color: white;
    gap: 5px;
    padding: 10px;
}
.mapMode>.mapModeBtn{
    text-align: center;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: black;
    background-color: rgb(255,255,255);
    box-shadow: 0px 5px 10px 0 rgba(0,0,0,0.02);
    border-radius: 50px;
}
@media only screen and (min-width: 544px) {
    .mapMode{
        background-color: white;
        gap: 10px;
        padding: 10px;
    }
    .mapMode>.mapModeBtn{
        padding: 10px;
        font-size: 12px;
    }
}
@media only screen and (min-width: 768px) {
    .mapMode{
        background-color: white;
        gap: 10px;
        padding: 10px;
    }
    .mapMode>.mapModeBtn{
        padding: 10px;
        font-size: 16px;
    }
}

.mapMode>.mapModeBtn.active{
    background-color: #1b75bc;
    color: white;
    background-image: linear-gradient(45deg, #004225 , #00910c );
}
.mapMode>.mapModeBtn:hover{
    box-shadow: 0px 5px 10px 0 rgba(0,0,0,0.1);
    background-color: rgb(255,255,255);
}
.lastUpdate{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
}
.mapSideBar,.markerPopup,.popupCompare{
    position: absolute;
    z-index: 1;
    top: 25px;
    bottom: 30px;
    left: 10px;
    transition: transform 0.3s;
}
.mapSideBar{
    width: 120px;
}
.mapSideBar>.arrowButton{
    position: absolute;
    top: 10px;
    left: 120px;
    width: 30px;
    text-align: right;
    height: 40px;
    background-color: white;
    border-radius: 0 8px 8px 0;
}
@media only screen and (min-width: 544px) {
    .mapSideBar,.markerPopup,.popupCompare{
        width: 250px;
    }
    .mapSideBar>.arrowButton{
        left: 250px;
    }
}
.mapSideBar>.arrowButton:hover{
    width: 40px;
}
.mapSideBar>.typeList{
    overflow: auto;
    height: calc(100% - 60px);
}
.mapSideBar>.typeList>label{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 0;
    border-top: 1px solid #efefef;
    font-weight: bold;
    font-size: 12px;
}
.mapSideBar>.typeList>label:hover{
    background-color: #efefef;
}
.mapSideBar>.typeList>label>.img{
    width: 30px;
    height: 30px;
}
@media only screen and (min-width: 544px) {
    .mapSideBar>.typeList>label{
        font-size: unset;
    }
    .mapSideBar>.typeList>label>.img{
        width: 50px;
        height: 50px;
    }
}
.markerPopup{
    left: unset;
    right: 65px;
    bottom: 23px;
    width: unset;
    animation: markerPopup 0.5s;
    /* display: none; */
}
@keyframes markerPopup {
    0%{opacity: 0; transform: translateX(100%) scale(0.8);}
    100%{opacity: 1; transform: translateX(0%) scale(1);}
}
.popupCompare{
    left: 50%;
    right: unset;
    bottom: unset;
    top: 50%;
    width: 95%;
    animation: popupCompare 0.5s;
    transform: translateY(-50%) translateX(-50%) scale(1);
}
@keyframes popupCompare {
    0%{opacity: 0; transform: translateY(-50%) translateX(-50%) scale(0.8); top: 0%;}
    100%{opacity: 1; transform: translateY(-50%) translateX(-50%) scale(1); top: 50%;}
}
.markerPopup .closeBtn{
    border-radius: 0px 8px 0px 0px;
}
.markerPopup>.titleName{
    float: left;
}
#fullscreenBtn{
    z-index: 1;
    position: absolute;
    top: 28px;
    right: 10px;
    color: rgb(86, 86, 86);
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    font-size: 18px;
    padding: 0 13px;
    line-height: 25px;
    border-radius: 3px;
    border: 0;
    height: 40px;
}
#fullscreenBtn:hover{
    color: rgb(51, 51, 51);
}
#comparisonBtn{
    z-index: 1;
    position: absolute;
    top: 25px;
    right: 60px;
    color: rgb(86, 86, 86);
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    font-size: 18px;
    padding: 0 13px;
    line-height: 25px;
    border-radius: 3px;
    border: 0;
    height: 40px;
}
#comparisonBtn:hover{
    color: rgb(51, 51, 51);
}
.multiSelectTag:hover:after {
  font-family: "Font Awesome 5 Free";
  content: "\f057";
  margin-left: 5px;
}
.rainLegends,.waterVapourLegends{
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(20px);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 30px;
}
.rainLegends{ min-width: 265px; }
.waterVapourLegends{padding: 10px 0; min-width: 230px;}
.rainLegends>div{
    padding: 0 10px;
}
.rainLegends>div>span{
    color: black;
    font-size: 12px;
    font-weight: bold;
}
.rainLegends>div>ul{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0px;
    gap: 3px;
}
.rainLegends>div>ul>li{
    font-size: 8px;
}
@media only screen and (min-width: 768px) {
    .rainLegends{
        padding: 10px 0;
    }
    .rainLegends>div{
        padding: 0 20px;
    }
    .rainLegends>div>span{
        font-size: 16px;
    }
    .rainLegends>div>ul>li{
        font-size: 14px;
    }
    .rainLegends>div>ul{
        gap: 5px;
    }
}
.waterVapourLegends{
    padding: 20px;
    flex-direction: column;
    color: rgb(50,50,50);
    font-size: 16px;
    font-weight: bold;
}
.waterVapourLegends>ul{
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
}
.waterVapourLegends>ul>li{
    flex: 1;
    height: 10px;
}
.waterVapourLegends>.title{
    text-align: center;
}
.waterVapourLegends>.smallLegends{
    width: 100%;
}
.satelliteLegends{
    color: white;
    background-color: rgba(0,0,0,0.5);
}