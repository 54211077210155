.weatherSummaryCard{
    color: white;
    background-image: radial-gradient(circle at 80% 0%,#00910c,#004225);
    /* background-image: radial-gradient(circle at 80% 0%,#5291ff,rgb(0 39 107)); */
    border-radius: 15px;
    /* margin: 10px 10px 0s 10px !important; */
    height: 100px;
    padding: 15px 0;
}
.localState{
    margin-top: -10px;
}
.weatherStatus{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 10px;
}
.weatherStatus>img{
    width: 30px !important;
}
.weatherStatus>h1{
    color: white;
    font-weight: lighter;
    font-size: 12px;
    margin: 0;
    width: 100%;
}
.weatherStatus>i{
    font-size: 10px;
    line-height: 10px;
}
.seperator{
    border-left: 2px solid white;
    border-radius: 10px;
    height: 50px;
    transform: rotateZ(30deg);
    margin: 0px 20px;
}
.tempArea>h1{
    font-size: 30px;
    margin: 0;
    line-height: 30px;
    font-weight: lighter;
    color: white;
}
.tempArea>h2,.feelLikeCont>h2,.moreBtn{
    color: white;
    font-weight: lighter;
    font-size: 8px;
    margin: 0;
    text-align: center;
    z-index: 1;
}
.feelLikeCont{
    display: inline-block;
}
.feelLike{
    border: 2px solid white;
    border-radius: 100px;
    width: 35px;
    height: 35px;
}
.feelLike>h1{
    font-size: 15px;
    margin: 0 -5px 0 0 ;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    color: black;
}
.feelLikeCont>h2{text-align: center;}
.moreBtn{
    font-size: 12px;
    text-align: right;
    margin: -10px 5px -10px 0;
    cursor: pointer;
}
.location{
    font-size: 8px;
}
.WSC_Header{
    background-color: rgba(0,0,0,0.5);
}
.w_location{
    color: white;
    background-color: rgba(0,0,0,0.5);
    border-radius: 20px;
    padding: 5px 15px;
    text-align: right;
}
.wind_area{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
}
.wind_area>h1{
    color: white;
    font-weight: lighter;
    font-size: 20px;
    margin: 0;
}
.wind_area>h2{
    color: white;
    font-weight: lighter;
    font-size: 20px;
    margin: 0;
}
.more_weather{
    border: solid white;
    border-width: 1px 0 0 0;
    padding: 15px 0 0 0;
}
.more_weather>img{
    width: 100px;
}
.more_weather h2{
    font-size: 18px;
    color: white;
    font-weight: lighter;
    margin: 0;
}
.more_weather h1{
    font-size: 20px;
    color: white;
    font-weight: lighter;
    margin: 0;
}
.more_weather .moreBtn{
    cursor: pointer;
}
.more_weather .moreBtn:hover{
    text-decoration: underline;
}
@media only screen and (min-width: 768px) {
    .weatherSummaryCard{
        padding: 20px 0;
        height: 120px;
        border-radius: 20px;
    }
    .WSC_Header{
        background-color: transparent;
    }
    .weatherStatus{
        justify-content: center;
    }
    .weatherStatus>img{
        width: 50px;
    }
    .weatherStatus>i{
        font-size: 13px;
        line-height: 25px;
    }
    .localState{
        margin-top: 0px;
    }
    .location{
        font-size: 10px;
    }
    .feelLike{
        width: 50px;
        height: 50px;
    }
    .feelLike>h1{
        font-size: 18px;
        margin: 0 -8px 0 0 ;
        line-height: 48px;
        font-weight: lighter;
    }
    .tempArea>h2,.feelLikeCont>h2,.moreBtn{
        font-size: 12px;
    }
    /* .more_weather{
        border-width: 0 0 0 1px;
        padding: 0 0 0 15px;
    } */
}
@media only screen and (min-width: 992px) {
    .weatherSummaryCard{
        padding: 20px 0;
        border-radius: 25px;
    }
    .weatherStatus>h1{
        font-size: 22px;
    }
    .weatherStatus>i{
        font-size: 15px;
        line-height: 25px;
    }
    .tempArea>h1{
        font-size: 50px;
        line-height: 50px;
    }
    .seperator{
        height: 70px;
        border-left: 3px solid white;
        margin: 5px 20px;
    }
    .feelLike{
        border: 3px solid white;
        width: 70px;
        height: 70px;
    }
    .feelLike>h1{
        font-size: 25px;
        line-height: 65px;
        margin: 0 -10px 0 0 ;
    }
    .weatherStatus>img{
        width: 40px !important;
    }
    .location{
        font-size: 12px;
    }


    .more_weather{
        border-width: 0 0 0 1px;
        padding: 0 0 0 15px;
    }
}
@media only screen and (min-width: 1200px) {
    .weatherSummaryCard{
        height: 145px;
        border-radius: 30px;
    }
    .seperator{
        height: 90px;
        border-left: 4px solid white;
        margin: 10px 20px;
    }
    .feelLike{
        border: 4px solid white;
        width: 90px;
        height: 90px;
    }
    .feelLike>h1{
        font-size: 30px;
        line-height: 80px;
    }
    .weatherStatus>h1{
        font-size: 30px;
    }
    .weatherStatus>i{
        font-size: 22px !important;
        line-height: 27px !important;
    }
    .tempArea>h1{
        font-size: 70px;
        line-height: 80px;
    }
    .tempArea>h2,.feelLikeCont>h2,.moreBt{
        font-size: 15px;
        text-align: center;
    }
    .location{
        font-size: 15px;
    }
    .moreBtn{
        font-size: 22px;
        line-height: 22px;
    }


    
    .wind_area>h1,.more_weather h1{
        font-size: 30px;
    }
    .more_weather h2{
        font-size: 25px;
    }
}
